import React from 'react';

function Matters(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                matters: e
            }
        })
    }

    return (
        <div className='matters'>
            <div className='header'>
                8. &nbsp;&nbsp;Matters Considered Essential as a Condition of Mortgage
            </div>
            <div>
                Please comment on repairs required or any specialist reports.
            </div>
            <textarea rows={5} value={props.form?.property?.matters} onChange={e => add("matters", e.currentTarget.value)} />
        </div>
    );
}

export default Matters;