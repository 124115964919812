import React from 'react';

function Accommodation(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                accommodation: {
                    ...props.form?.property?.accommodation,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='accommodation'>
            <div className='header'>
                3e. &nbsp;Accommodation
            </div>

            <div className='input' style={{ marginBottom: "10px" }}>
                <div className='label'>
                    What is the approximate square footage?
                </div>
                <input style={{width: "100px", textAlign: "left"}} type="number" value={props.form?.property?.accommodation?.floorspace} onChange={e => add("floorspace", e.currentTarget.value)} /> sqft
            </div>

            <br />

            {getInput("Number of floors", "floors")}

            <div style={{ margin: "10px 0px 20px" }}>
                Enter number of:
            </div>

            {getInput("Living rooms", "living_rooms")}
            <div className='break'></div>
            {getInput("Bedrooms", "bedrooms")}
            <div className='break'></div>
            {getInput("Bathrooms", "bathrooms")}
            <div className='break'></div>
            {getInput("Kitchens", "kitchens")}
            <div className='break'></div>
            {getInput("Toilets", "toilets")}
            <div className='break'></div>
            {getInput("Garages", "garages")}
            <div className='break'></div>
            {getInput("Outbuildings", "outbuildings")}
            <div className='break'></div>
            {getInput("Other", "other")}

            <div className='input' style={{ width: "100%" }}>
                <div className='label'>
                    Use of outbuildings
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.accommodation?.use_of_outbuildings} onChange={e => add("use_of_outbuildings", e.currentTarget.value)} />
            </div>
        </div>
    );

    function getInput(title, state) {
        return <div className='input'>
            <div className='label'>
                {title}
            </div>
            <input type="number" value={props.form?.property?.accommodation?.[state]} onChange={e => add(state, e.currentTarget.value)} />
        </div>
    }
}

export default Accommodation;