import React from 'react';

function GeneralRemarks(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                general_remarks: e
            }
        })
    }

    return (
        <div className='remarks'>
            <div className='header'>
                9. &nbsp;&nbsp;General Remarks
            </div>
            <div>
                Please comment on any other factors and saleability.
            </div>
            <textarea rows={5} value={props.form?.property?.general_remarks} onChange={e => add("general_remarks", e.currentTarget.value)} />
        </div>
    );
}

export default GeneralRemarks;