import React, { useEffect } from 'react';

function Description(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                description: {
                    ...props.form?.property?.description,
                    [state]: e
                }
            }
        })
    }

    function addToFlat(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                description: {
                    ...props.form?.property?.description,
                    flat: {
                        ...props.form?.property?.description?.flat,
                        [state]: e
                    }
                }
            }
        })
    }

    function checked(value) {
        return props.form?.property?.description?.type === value
    }

    return (
        <div className='description'>
            <div className='header'>
                3a. &nbsp;Description and Type of Property
            </div>

            <label class="container">Detached
                <input type="checkbox" checked={checked("detached")} onChange={() => add("type", "detached")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Semi-Detached
                <input type="checkbox" checked={checked("semi-detached")} onChange={() => add("type", "semi-detached")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Terraced
                <input type="checkbox" checked={checked("terraced")} onChange={() => add("type", "terraced")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Bungalow
                <input type="checkbox" checked={checked("bungalow")} onChange={() => add("type", "bungalow")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Back-to-Back
                <input type="checkbox" checked={checked("back-to-back")} onChange={() => add("type", "back-to-back")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Flat
                <input type="checkbox" checked={checked("flat")} onChange={() => add("type", "flat")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Studio-Flat
                <input type="checkbox" checked={checked("studio")} onChange={() => add("type", "studio")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Other (details below)
                <input type="checkbox" checked={checked("other")} onChange={() => add("type", "other")} />
                <span class="checkmark"></span>
            </label>

            <div className='input'>
                <div className='label'>
                    Details if Other
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.description?.details} onChange={e => add("details", e.currentTarget.value)} />
            </div>

            <div className="flat">
                <div className="header">
                    If Flat
                </div>

                <div>
                    <label class="container">Purpose-Built
                        <input type="checkbox" checked={props.form?.property?.description?.flat?.type === "purpose-built"} onChange={e => addToFlat("type", "purpose-built")} />
                        <span class="checkmark"></span>
                    </label>

                    <label class="container">Converted
                        <input type="checkbox" checked={props.form?.property?.description?.flat?.type === "converted"} onChange={e => addToFlat("type", "converted")} />
                        <span class="checkmark"></span>
                    </label>
                </div>

                <div className='input'>
                    <div className='label'>
                        Total number of floors in block
                    </div>
                    <input type="number" value={props.form?.property?.description?.flat?.floors_in_block} onChange={e => addToFlat("floors_in_block", parseInt(e.currentTarget.value))} />
                </div>

                <div className='input'>
                    <div className='label'>
                        Which floor is the flat on?
                    </div>
                    <input type="number" value={props.form?.property?.description?.flat?.floor} onChange={e => addToFlat("floor", parseInt(e.currentTarget.value))} />
                </div>

                <div className='input'>
                    <div className='label'>
                        How many units in the block?
                    </div>
                    <input type="number" value={props.form?.property?.description?.flat?.units_in_block} onChange={e => addToFlat("units_in_block", parseInt(e.currentTarget.value))} />
                </div>

                <div className='boolean'>
                    <div className='label'>
                        Is there a lift?
                    </div>
                    <label class="container">Yes
                        <input type="checkbox" checked={props.form?.property?.description?.flat?.lift} onChange={() => addToFlat("lift", true)} />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container">No
                        <input type="checkbox" checked={props.form?.property?.description?.flat?.lift === false} onChange={() => addToFlat("lift", false)} />
                        <span class="checkmark"></span>
                    </label>
                </div>

                <div className='boolean'>
                    <div className='label'>
                        Any retail uses in block?
                    </div>
                    <label class="container">Yes
                        <input type="checkbox" checked={props.form?.property?.description?.flat?.retail_use_in_block} onChange={() => addToFlat("retail_use_in_block", true)} />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container">No
                        <input type="checkbox" checked={props.form?.property?.description?.flat?.retail_use_in_block === false} onChange={() => addToFlat("retail_use_in_block", false)} />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>

            <div className='input'>
                <div className='label'>
                    Details of any retail uses
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.description?.flat?.retail_use_details} onChange={e => addToFlat("retail_use_details", e.currentTarget.value)} />
            </div>
        </div>
    );
}

export default Description;