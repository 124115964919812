import React from 'react';

function Applicant(props) {
    function add(state, e) {
        props.addToForm({
            applicant: {
                ...props.form?.applicant,
                [state]: e.currentTarget.value
            }
        })
    }

    return (
        <div className='applicant'>
            <div className='header'>
                1. &nbsp;&nbsp;Application Details
            </div>

            <div className='input'>
                <div className='label'>
                    Applicant Name
                </div>
                <input type="text" value={props.form?.applicant?.name} onChange={e => add("name", e)} />
            </div>

            {/* <div className='input'>
                <div className='label'>
                    Branch
                </div>
                <input type="text" value={props.form?.applicant?.branch} onChange={e => add("branch", e)} />
            </div>

            <div className='input'>
                <div className='label'>
                    Account No.
                </div>
                <input type="text" value={props.form?.applicant?.account} onChange={e => add("account", e)} />
            </div>

            <div className='input'>
                <div className='label'>
                    MSC
                </div>
                <input type="text" value={props.form?.applicant?.msc} onChange={e => add("msc", e)} />
            </div> */}
        </div>
    );
}

export default Applicant;