import React from 'react';

function PropertyDetails(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                details: {
                    ...props.form?.property?.details,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='property-details'>
            <div className='header'>
                3i. &nbsp;Property Details
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Is the property listed?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.details?.listed} onClick={() => add("listed", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.details?.listed === false} onClick={() => add("listed", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div style={{marginTop: "-40px"}} className='input'>
                <div className='label'>
                    If yes, is it grade I/grade II/grade II*?
                </div>
                <select value={props.form?.property?.details?.grade || null} onChange={e => add("grade", e.currentTarget.value)}>
                    <option value="" disabled selected>Select grade</option>
                    <option value="1">Grade I</option>
                    <option value="2">Grade II</option>
                    <option value="3">Grade II*</option>
                </select>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Is it subject to any form of flying, creeping or submerged freehold?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.details?.subject_to_encroaching_freehold} onClick={() => add("subject_to_encroaching_freehold", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.details?.subject_to_encroaching_freehold === false} onClick={() => add("subject_to_encroaching_freehold", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div style={{marginTop: "-40px"}} className='input'>
                <div className='label'>
                    If yes, state percentage
                </div>
                <input type="number" min={0} max={100} value={props.form?.property?.details?.subject_to_encroaching_freehold_percent} onChange={e => add("subject_to_encroaching_freehold_percent", parseInt(e.currentTarget.value))} /> %
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Does the property have any form of flat roof?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.details?.flat_roof} onClick={() => add("flat_roof", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.details?.flat_roof === false} onClick={() => add("flat_roof", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div style={{marginTop: "-40px"}} className='input'>
                <div className='label'>
                    If yes, state percentage
                </div>
                <input type="number" min={0} max={100} value={props.form?.property?.details?.flat_roof_percent} onChange={e => add("flat_roof_percent", parseInt(e.currentTarget.value))} /> %
            </div>

            <div style={{marginTop: "-10px", display: "block"}} className='input'>
                <div className='label'>
                    If yes, supply details
                </div>
                <textarea type="text" rows={2}  value={props.form?.property?.details?.flat_roof_details} onChange={e => add("flat_roof_details", e.currentTarget.value)} />
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Does the building have cladding?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.details?.cladding} onClick={() => add("cladding", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.details?.cladding === false} onClick={() => add("cladding", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Does the building have balconies?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.details?.balconies} onClick={() => add("balconies", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.details?.balconies === false} onClick={() => add("balconies", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Is an EWS form required?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.details?.ews_form_required} onClick={() => add("ews_form_required", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.details?.ews_form_required === false} onClick={() => add("ews_form_required", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    );
}

export default PropertyDetails;