import React from 'react';

function Disclosure(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                disclosure: {
                    ...props.form?.property?.disclosure,
                    [state]: e
                }
            }
        })
    }

    function addCML(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                disclosure: {
                    ...props.form?.property?.disclosure,
                    cml_disclosure: {
                        ...props.form?.property?.disclosure?.cml_disclosure,
                        [state]: e
                    }
                }
            }
        })
    }

    return (
        <div className='disclosure'>
            <div className='header'>
                3c. &nbsp;CML Disclosure of Incentives
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Is this a new build, recently converted and/or refurbished property that has yet to be purchased or occupied in its current form?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.disclosure?.new_build} onClick={() => add("new_build", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.disclosure?.new_build === false} onClick={() => add("new_build", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    The CML Disclosure form signed by &nbsp;
                    <input type="text" value={props.form?.property?.disclosure?.cml_disclosure?.signed} onChange={e => addCML("signed", e.currentTarget.value)} />
                    &nbsp;&nbsp;dated&nbsp;
                    <input type="date" value={props.form?.property?.disclosure?.cml_disclosure?.date} onChange={e => addCML("date", e.currentTarget.value)} />
                    &nbsp;&nbsp;has been seen and taken into account
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.disclosure?.cml_disclosure?.seen} onClick={() => addCML("seen", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.disclosure?.cml_disclosure?.seen === false} onClick={() => addCML("seen", false)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">N/A
                    <input type="checkbox" checked={props.form?.property?.disclosure?.cml_disclosure?.seen === null} onClick={() => addCML("seen", null)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div>
                If No or N/A, please explain in the General Remarks box below.
            </div>
        </div>
    );
}

export default Disclosure;