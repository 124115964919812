import React from 'react';

function SolarPanels(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                solar_panels: {
                    ...props.form?.property?.solar_panels,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='solar-panels'>
            <div className='header'>
                7. &nbsp;&nbsp;Solar Panels
            </div>

            {getBoolean("Are solar panels present on the property?", "present")}
            {getBoolean("If yes, is the equipment causing damage?", "causing_damage")}
            {getBoolean("Is the saleability affected?", "saleability_affected")}
            {getBoolean("If known, is the equipment currently in use?", "currently_in_use", true)}
            {getBoolean("Is the roof subject to a lease to a third party for the equipment?", "roof_subject_to_lease", true)}
        </div>
    );

    function getBoolean(text, state, na) {
        return <div className='boolean'>
            <div className='label' style={{ marginBottom: "10px" }}>
                {text}
            </div>
            <label class="container">Yes
                <input type="checkbox" checked={props.form?.property?.solar_panels?.[state]} onClick={() => add(state, true)} />
                <span class="checkmark"></span>
            </label>
            <label class="container">No
                <input type="checkbox" checked={props.form?.property?.solar_panels?.[state] === false} onClick={() => add(state, false)} />
                <span class="checkmark"></span>
            </label>
            {na ?
                <label class="container">Unknown
                    <input type="checkbox" checked={props.form?.property?.solar_panels?.[state] === null} onClick={() => add(state, null)} />
                    <span class="checkmark"></span>
                </label> : null
            }
        </div>
    }
}

export default SolarPanels;