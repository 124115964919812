import React, { useEffect, useState } from 'react';
import agent from './agent/agent';
import "./index.sass"
import Accommodation from './parts/accommodation';
import Age from './parts/age';
import Applicant from './parts/applicant';
import Construction from './parts/construction';
import Description from './parts/description';
import Desirability from './parts/desirability';
import Details from './parts/details';
import Disclosure from './parts/disclosure';
import Features from './parts/features';
import GeneralRemarks from './parts/generalRemarks';
import Heading from './parts/heading';
import Land from './parts/land';
import Matters from './parts/matters';
import PropertyDetails from './parts/propertyDetails';
import Rights from './parts/rights';
import Roads from './parts/roads';
import Services from './parts/services';
import SolarPanels from './parts/solarPanels';
import Subsidence from './parts/subsidence';
import Suitability from './parts/suitability';
import Tenure from './parts/tenure';
import Valuation from './parts/valuations';

function Form(props) {
    const [form, setForm] = useState({})
    const [id, setId] = useState(null)
    const [search, setSearch] = useState("")

    const [loading, setLoading] = useState(true)

    function addToForm(state) {
        setForm({
            ...form,
            ...state
        })
    }

    useEffect(() => {
        if (id) {
            if (id.match(/^[0-9a-fA-F]{24}$/)) {
                getCurrent()
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [id])

    async function getCurrent() {
        setLoading(true)
        let current = await agent.form.current(id)
        if (current.success) {
            setForm(current.data.form)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        console.log(form)
    }, [form])

    useEffect(() => {
        let url = window.location.search.replace("?", "").split("=")
        if (url[0] === "id")
            if (url[1]) {
                if (url[1].match(/^[0-9a-fA-F]{24}$/)) {
                    setLoading(false)
                    setId(url[1])
                } else {
                    setLoading(false)
                }
            }
    }, [window.location.search])

    if (loading === true) {
        return <div className='loading'>
            <div className='spinner'></div>
        </div>
    }
    else if (id) {
        return (
            <div className='form'>
                <Heading />
                <Applicant form={form} addToForm={addToForm} />
                <Details form={form} addToForm={addToForm} />
                <Description form={form} addToForm={addToForm} />
                <Age form={form} addToForm={addToForm} />
                <Disclosure form={form} addToForm={addToForm} />
                <Construction form={form} addToForm={addToForm} />
                <Accommodation form={form} addToForm={addToForm} />
                <Services form={form} addToForm={addToForm} />
                <Land form={form} addToForm={addToForm} />
                <Tenure form={form} addToForm={addToForm} />
                <PropertyDetails form={form} addToForm={addToForm} />
                <Features form={form} addToForm={addToForm} />

                <Roads form={form} addToForm={addToForm} />
                <Rights form={form} addToForm={addToForm} />
                <Subsidence form={form} addToForm={addToForm} />
                <SolarPanels form={form} addToForm={addToForm} />
                <Matters form={form} addToForm={addToForm} />
                <GeneralRemarks form={form} addToForm={addToForm} />
                <Suitability form={form} addToForm={addToForm} />
                <Valuation form={form} addToForm={addToForm} />
                <Desirability form={form} addToForm={addToForm} />

                <button id="response" onClick={async () => {
                    let save = await agent.form.save(id, form)
                    if (save.success) {
                        document.getElementById("response").innerHTML = save.message + " ✔"
                        document.getElementById("response").style.color = "#26c226"
                        setTimeout(() => {
                            document.getElementById("response").innerHTML = "Submit"
                            document.getElementById("response").style.color = "white"
                        }, 3000)
                    }
                }}>
                    Submit
                </button>

                {/* <div id="response" style={{ color: "green", textAlign: "center", fontWeight: 600 }}></div> */}
            </div>
        )
    } else return <div className='form'>
        <Heading />
        <div style={{ textAlign: "center", marginTop: "60px" }}>
            Enter ID
            <br /><br />
            <input style={{ border: "1px solid black", padding: "5px 10px", fontSize: "18px" }} type="text" value={id} onChange={e => setSearch(e.currentTarget.value)} />
            <button onClick={() => {
                setLoading(true)
                window.location.href = "/?id=" + search
            }} style={{ border: "1px solid black", backgroundColor: "black", color: "white", cursor: "pointer", padding: "5px 10px", fontSize: "18px" }}>GO</button>
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }} id="message"></div>
    </div>
}

export default Form;