import React from 'react';

function Subsidence(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                subsidence: {
                    ...props.form?.property?.subsidence,
                    [state]: e
                }
            }
        })
    }

    function addMovement(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                subsidence: {
                    ...props.form?.property?.subsidence,
                    movement: {
                        ...props.form?.property?.subsidence?.movement,
                        [state]: e
                    }
                }
            }
        })
    }

    function addTrees(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                subsidence: {
                    ...props.form?.property?.subsidence,
                    trees: {
                        ...props.form?.property?.subsidence?.trees,
                        [state]: e
                    }
                }
            }
        })
    }

    return (
        <div className='subsidence'>
            <div className='header'>
                6. &nbsp;&nbsp;Subsidence/Structural Damage
            </div>

            {getBoolean("a. Is the property visibly affected by movement?", "affected_by_movement")}
            {getMovementBoolean("i. Is the movement long-standing and non-progressive?", "long_standing_non_progressive", true)}
            {getMovementBoolean("ii. Is the movement likely to be progressive?", "likely_progressive", true)}
            {getMovementBoolean("iii. Is the saleability affected?", "saleability_affected", true)}

            {getBoolean("b. Is a structural engineer's report required?", "structural_engineer_report_required")}
            {getBoolean("c. Is the property a coal mining area?", "coal_mining_area")}
            {getBoolean("d. Is a coal mining search required?", "coal_mining_search_required")}

            {getBoolean("e. Are there any trees within influencing distance?", "trees_in_influencing_distance")}
            {getTreeBoolean("i. If yes, are the trees within the boundary of the security?", "in_boundary")}

            <div>ii. Please provide the:</div>
            <div style={{ display: "block" }} className='input'>
                <div className='label'>
                    Number
                </div>
                <input type="number" value={props.form?.property?.subsidence?.trees?.number} onChange={e => add("number", parseInt(e.currentTarget.value))} />
            </div>
            <div style={{ display: "block" }} className='input'>
                <div className='label'>
                    Height
                </div>
                <input type="number" value={props.form?.property?.subsidence?.trees?.height} onChange={e => add("height", parseInt(e.currentTarget.value))} />
            </div>
            <div style={{ display: "block" }} className='input'>
                <div className='label'>
                    Type
                </div>
                <input style={{ width: "200px" }} type="text" value={props.form?.property?.subsidence?.trees?.type} onChange={e => add("type", parseInt(e.currentTarget.value))} />
            </div>
            <div style={{ display: "block" }} className='input'>
                <div className='label'>
                    Distance from the security
                </div>
                <input type="number" value={props.form?.property?.subsidence?.trees?.distance_from_boundary} onChange={e => add("distance_from_boundary", parseInt(e.currentTarget.value))} />
            </div>

            {getBoolean("f. Is a tree surgeon's report required?", "tree_surgeon_report_required")}
            {getBoolean("g. Is there any evidence of JK?", "evidence_of_jk")}
        </div>
    );

    function getBoolean(text, state) {
        return <div className='boolean'>
            <div className='label' style={{ marginBottom: "10px" }}>
                {text}
            </div>
            <label class="container">Yes
                <input type="checkbox" checked={props.form?.property?.subsidence?.[state]} onClick={() => add(state, true)} />
                <span class="checkmark"></span>
            </label>
            <label class="container">No
                <input type="checkbox" checked={props.form?.property?.subsidence?.[state] === false} onClick={() => add(state, false)} />
                <span class="checkmark"></span>
            </label>
        </div>
    }

    function getMovementBoolean(text, state, na) {
        return <div className='boolean'>
            <div className='label' style={{ marginBottom: "10px" }}>
                {text}
            </div>
            <label class="container">Yes
                <input type="checkbox" checked={props.form?.property?.subsidence?.movement?.[state] === true} onClick={() => addMovement(state, true)} />
                <span class="checkmark"></span>
            </label>
            <label class="container">No
                <input type="checkbox" checked={props.form?.property?.subsidence?.movement?.[state] === false} onClick={() => addMovement(state, false)} />
                <span class="checkmark"></span>
            </label>
            {na ?
                <label class="container">N/A
                    <input type="checkbox" checked={props.form?.property?.subsidence?.movement?.[state] === null} onClick={() => addMovement(state, null)} />
                    <span class="checkmark"></span>
                </label> : null
            }
        </div>
    }

    function getTreeBoolean(text, state, na) {
        return <div className='boolean'>
            <div className='label' style={{ marginBottom: "10px" }}>
                {text}
            </div>
            <label class="container">Yes
                <input type="checkbox" checked={props.form?.property?.subsidence?.movement?.tree?.[state] === true} onClick={() => addTrees(state, true)} />
                <span class="checkmark"></span>
            </label>
            <label class="container">No
                <input type="checkbox" checked={props.form?.property?.subsidence?.movement?.trees?.[state] === false} onClick={() => addTrees(state, false)} />
                <span class="checkmark"></span>
            </label>
            {na ?
                <label class="container">N/A
                    <input type="checkbox" checked={props.form?.property?.subsidence?.movement?.trees?.[state] === null} onClick={() => addTrees(state, null)} />
                    <span class="checkmark"></span>
                </label> : null
            }
        </div>
    }
}

export default Subsidence;