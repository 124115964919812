import React from 'react';

function Valuation(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                valuation: {
                    ...props.form?.property?.valuation,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='valuation'>
            <div className='header'>
                11. &nbsp;Valuation Details
            </div>

            <div style={{ display: "inline-block" }} className='input'>
                <div className='label'>
                    Valuation in present condition 
                </div>
                <br/>
                &nbsp;&nbsp;£<input type="number" max={10000000} value={props.form?.property?.valuation?.current_condition} onChange={e => add("current_condition", parseInt(e.currentTarget.value))} />
            </div>

            <div style={{ display: "inline-block" }} className='input'>
                <div className='label'>
                    Valuation when improved
                </div>
                <br/>
                &nbsp;&nbsp;£<input type="number" max={10000000} value={props.form?.property?.valuation?.when_improved} onChange={e => add("when_improved", parseInt(e.currentTarget.value))} />
            </div>

            <div style={{ display: "inline-block" }} className='input'>
                <div className='label'>
                    Valuation for land only (if applicable)
                </div>
                <br/>
                &nbsp;&nbsp;£<input type="number" max={10000000} value={props.form?.property?.valuation?.land_value} onChange={e => add("land_value", parseInt(e.currentTarget.value))} />
            </div>

            <div style={{ display: "inline-block" }} className='input'>
                <div className='label'>
                    Rental valuation
                </div>
                <br/>
                &nbsp;&nbsp;£<input type="number" max={10000000} value={props.form?.property?.valuation?.rent} onChange={e => add("rent", parseInt(e.currentTarget.value))} /> /pcm
            </div>
        </div>
    );
}

export default Valuation;