import React from 'react';

function Desirability(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                [state]: e
            }
        })
    }

    function checked(state, value) {
        return props.form?.property?.[state] === value
    }

    return (
        <div className='desirability'>
            <div className='header'>
                12. &nbsp;Desirability
            </div>

            <div style={{marginBottom: "20px"}}>Which of these best describes the property?</div>

            {getCheckbox("5 - Period property, high ceilings, bells and whistles", 5)}
            {getCheckbox("4 - Period property, spacious", 4)}
            {getCheckbox("3 - Period property, cramped (e.g. mid-terrace, 2 up 2 down)", 3)}
            {getCheckbox("2 - Ex-council, spacious", 2)}
            {getCheckbox("1 - Ex-council, you wouldn't want to walk there at night", 1)}
            {getCheckbox("0 - New builds (anything built after the 1940s)", 0)}
        </div>
    );

    function getCheckbox(text, value) {
        return <label class="container">{text}
            <input type="checkbox" checked={checked("desirability", value)} onChange={!checked("desirability", value) ? () => add("desirability", value) : () => add("desirability", null)} />
            <span class="checkmark"></span>
        </label>
    }
}

export default Desirability;