import React from 'react';

function Land(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                land: {
                    ...props.form?.property?.land,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='land'>
            <div className='header'>
                3g. &nbsp;Land
            </div>

            <div style={{marginBottom: "20px"}}>If the property to be mortgaged comprises more than 0.4ha/1 acre or outbuildings in addition to garages, please comment.</div>

            <div className='input'>
                <div className='label'>
                    Approximate area
                </div>
                <input type="number" value={props.form?.property?.land?.area} onChange={e => add("area", parseInt(e.currentTarget.value))} /> acre(s)
            </div>
            <br />
            <div className='input'>
                <div className='label'>
                    Use of land
                </div>
                <input type="text" value={props.form?.property?.land?.use} onChange={e => add("use", e.currentTarget.value)} />
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Any agricultural occupancy restrictions?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.land?.agriculture_restrictions} onClick={() => add("agriculture_restrictions", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.land?.agriculture_restrictions === false} onClick={() => add("agriculture_restrictions", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    );
}

export default Land;