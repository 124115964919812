import React from 'react';

function Suitability(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                suitability: e
            }
        })
    }

    return (
        <div className='suitability'>
            <div className='header'>
                10. &nbsp;Suitability
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Do you consider the property to form suitable security for mortgage purposes?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.suitability} onClick={() => add("suitability", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.suitability === false} onClick={() => add("suitability", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    );
}

export default Suitability;