import React from 'react';

function Age(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                age: {
                    ...props.form?.property?.age,
                    [state]: e
                }
            }
        })
    }

    function addDocument(state, e) {
        if (state !== "none") {
            props.addToForm({
                property: {
                    ...props.form?.property,
                    age: {
                        ...props.form?.property?.age,
                        available_documents: {
                            ...props.form?.property?.age?.available_documents,
                            [state]: e,
                            none: undefined
                        }
                    }
                }
            })
        } else {
            props.addToForm({
                property: {
                    ...props.form?.property,
                    age: {
                        ...props.form?.property?.age,
                        available_documents: {
                            none: true
                        }
                    }
                }
            })
        }
    }

    return (
        <div className='age'>
            <div className='header'>
                3b. &nbsp;Age of Property
            </div>

            <div className='input'>
                <div className='label'>
                    Year of Construction
                </div>
                <input type='number' rows={2} value={props.form?.property?.age?.year} onChange={e => add("year", e.currentTarget.value)} />
            </div>

            <div className='break'></div>

            <div className='input'>
                <div className='label'>
                    Year of Extension
                </div>
                <input type='number' rows={2} value={props.form?.property?.age?.extension} onChange={e => add("extension", e.currentTarget.value)} />
            </div>

            <div className='break'></div>

            <div className='input'>
                <div className='label'>
                    Year of Conversion
                </div>
                <input type='number' rows={2} value={props.form?.property?.age?.conversion} onChange={e => add("conversion", e.currentTarget.value)} />
            </div>

            <div className='input' style={{ width: "100%" }}>
                <div className='label'>
                    Stage of Construction (if new property)
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.age?.state_of_construction} onChange={e => add("state_of_construction", e.currentTarget.value)} />
            </div>

            <br /><br />

            <div className='input'>
                <div className='label'>
                    Are any of the following available if less than 10 years old?
                </div>
            </div>

            <br /><br />

            {getChecker("NHBC Certificate", "nhbc_certificate")}
            {getChecker("Architects Certificates", "architects_certificate")}
            {getChecker("PCC", "pcc")}
            {getChecker("Premier Guarantee", "premier_guarantee")}
            {getChecker("Other", "other")}
            {getChecker("None", "none")}
        </div>
    );

    function getChecker(title, form) {
        return <label class="container">{title}
            <input type="checkbox" checked={props.form?.property?.age?.available_documents?.[form] === true} onChange={() => addDocument(form, !props.form?.property?.age?.available_documents?.[form])} />
            <span class="checkmark"></span>
        </label>
    }
}

export default Age;