import React from 'react';

function Features(props) {
    function add(e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                features: props.form?.property?.features ? [...props.form?.property?.features, e] : [e]
            }
        })
    }

    function remove(e) {
        let arr = [...props.form?.property?.features]
        arr.splice(arr.indexOf(e), 1)
        props.addToForm({
            property: {
                ...props.form?.property,
                features: arr
            }
        })
    }

    return (
        <div className='features'>
            <div className='header'>
                3j. &nbsp;Features
            </div>

            <div style={{marginBottom: "20px"}}>
                Which of these does the property have? Tick all that apply
            </div>

            {getFeature("Garden")}
            {getFeature("Garage")}
            {getFeature("Parking")}
            {getFeature("Fireplace")}
            {getFeature("Central Heating")}
            {getFeature("Double Glazing")}
            {getFeature("Phone Signal")}
            {getFeature("Bath")}
            {getFeature("Shower")}
            {getFeature("Insulation")}
            {getFeature("Satellite TV")}
            {getFeature("Dining Rooms")}
            {getFeature("Secure Windows")}
            {getFeature("Secure Doors")}
            {getFeature("High Ceilings")}
            {getFeature("Strong Wifi")}
            {getFeature("Period Features")}
            {getFeature("Washing Machine")}
            {getFeature("Dishwasher")}
            {getFeature("Oven")}
            {getFeature("Freezer")}
            {getFeature("Fridge")}
            {getFeature("Office")}
            {getFeature("Air Conditioning")}
        </div>
    );

    function getFeature(text) {
        return <label class="container">{text}
            <input type="checkbox" checked={props.form?.property?.features?.includes(text?.toLowerCase()?.replace(" ", "_"))} onChange={!props.form?.property?.features?.includes(text?.toLowerCase()?.replace(" ", "_")) ? () => add(text?.toLowerCase()?.replace(" ", "_")) : () => remove(text?.toLowerCase()?.replace(" ", "_"))} />
            <span class="checkmark"></span>
        </label>
    }
}

export default Features;