import React from 'react';

function Rights(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                rights: {
                    ...props.form?.property?.rights,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='rights'>
            <div className='header'>
                5. &nbsp;Rights of Way
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Are there any obvious rights of way/easements adversely affecting the property or essential for the use of the property?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.rights?.rights_of_way_or_easements} onClick={() => add("rights_of_way_or_easements", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.rights?.rights_of_way_or_easements === false} onClick={() => add("rights_of_way_or_easements", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Are there any shared access ways to the property/garage/ancillary buildings?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.rights?.shared_access} onClick={() => add("shared_access", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.rights?.shared_access === false} onClick={() => add("shared_access", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='input' style={{ width: "100%" }}>
                <div className='label'>
                    If yes, supply details
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.rights?.details} onChange={e => add("details", e.currentTarget.value)} />
            </div>
        </div>
    );
}

export default Rights;