import React from 'react';

function Tenure(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                tenure: {
                    ...props.form?.property?.tenure,
                    [state]: e
                }
            }
        })
    }

    function addLeasehold(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                tenure: {
                    ...props.form?.property?.tenure,
                    tenure: {
                        ...props.form?.property?.disclosure?.tenure,
                        [state]: e
                    }
                }
            }
        })
    }

    return (
        <div className='tenure'>
            <div className='header'>
                3h. &nbsp;Tenure
            </div>

            <label class="container">Leasehold
                <input type="checkbox" checked={props.form?.property?.tenure?.type === "leasehold"} onChange={() => add("type", "leasehold")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Freehold
                <input type="checkbox" checked={props.form?.property?.tenure?.type === "freehold"} onChange={() => add("type", "freehold")} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Former Feuhold
                <input type="checkbox" checked={props.form?.property?.tenure?.type === "feuhold"} onChange={() => add("type", "feuhold")} />
                <span class="checkmark"></span>
            </label>

            <div style={{ margin: "10px 0px 20px" }}>
                If leasehold:
            </div>

            <div className='input'>
                <div className='label'>
                    Unexpired term
                </div>
                <input type="number" value={props.form?.property?.tenure?.leasehold?.unexpired_term} onChange={e => addLeasehold("unexpired_term", parseInt(e.currentTarget.value))} /> year(s)
            </div>
            <br/>
            <div className='input'>
                <div className='label'>
                    Ground rent &nbsp;&nbsp;£
                </div>
                <input style={{marginLeft: "-25px", backgroundColor: "transparent", paddingLeft: "20px"}} type="number" value={props.form?.property?.tenure?.leasehold?.ground_rent} onChange={e => addLeasehold("ground_rent", parseInt(e.currentTarget.value))} />
            </div>
            <br/>
            <div className='input'>
                <div className='label'>
                    Service charge &nbsp;&nbsp;£
                </div>
                <input style={{marginLeft: "-25px", backgroundColor: "transparent", paddingLeft: "20px"}} type="number" value={props.form?.property?.tenure?.leasehold?.service_charge} onChange={e => addLeasehold("service_charge", parseInt(e.currentTarget.value))} />
            </div>

            <div className='input' style={{ width: "100%" }}>
                <div className='label'>
                    Other comments
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.tenure?.other} onChange={e => add("other", e.currentTarget.value)} />
            </div>
        </div>
    );
}

export default Tenure;