import React from 'react';

function Heading(props) {
    return (
        <div className='heading'>
            More Simple Money survey form
        </div>
    );
}

export default Heading;