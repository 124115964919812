import React from 'react';

function Details(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                [state]: e.currentTarget.value
            }
        })
    }

    return (
        <div className='details'>
            <div className='header'>
                2. &nbsp;&nbsp;Property Details
            </div>

            <div className='input'>
                <div className='label'>
                    Address
                </div>
                <textarea rows='4' value={props?.property?.address} onChange={e => add("address", e)} />
            </div>
        </div>
    );
}

export default Details;