import React from 'react';

function Services(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                services: {
                    ...props.form?.property?.services,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='services'>
            <div className='header'>
                3f. &nbsp;Services
            </div>

            <label class="container">Mains Electricity
                <input type="checkbox" checked={props.form?.property?.services?.electricity} onChange={() => add("electricity", !props.form?.property?.services?.electricity)} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Mains Water
                <input type="checkbox" checked={props.form?.property?.services?.water} onChange={() => add("water", !props.form?.property?.services?.water)} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Mains Gas
                <input type="checkbox" checked={props.form?.property?.services?.gas} onChange={() => add("gas", !props.form?.property?.services?.gas)} />
                <span class="checkmark"></span>
            </label>

            <label class="container">Mains Drainage
                <input type="checkbox" checked={props.form?.property?.services?.drainage} onChange={() => add("drainage", !props.form?.property?.services?.drainage)} />
                <span class="checkmark"></span>
            </label>

            <div className='input' style={{ width: "100%" }}>
                <div className='label'>
                    Other services
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.services?.other} onChange={e => add("other", e.currentTarget.value)} />
            </div>
        </div>
    );
}

export default Services;