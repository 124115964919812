import superagent from "superagent"
// import config from "./configuration"

let config = {
    // api: "http://192.168.8.100:3002"
    api: "https://p4-microservice.herokuapp.com"
}

let url = config.api

const api = {
    post: (path, body) => {
        return superagent
            .post(url + path)
            .set("x-api-key", process.env.REACT_APP_API_KEY)
            .send(body)
            .then(res => {
                return res.body
            })
            .catch(err => console.log(err))
    }
}

let form = {
    current: (uid) => { return api.post("/survey/current", { uid }) },
    save: (uid, form) => { return api.post("/survey/save", { uid, form }) }
}

let agent = {
    form
}

export default agent