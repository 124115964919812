import React from 'react';

function Construction(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                construction: {
                    ...props.form?.property?.construction,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='construction'>
            <div className='header'>
                3d. &nbsp;Construction
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Standard Construction
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.construction?.standard_construction} onClick={() => add("standard_construction", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.construction?.standard_construction === false} onClick={() => add("standard_construction", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Timber Frame Masonry Clad
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.construction?.timber_frame_masonry_clad} onClick={() => add("timber_frame_masonry_clad", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.construction?.timber_frame_masonry_clad === false} onClick={() => add("timber_frame_masonry_clad", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Reinforced Concrete
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.construction?.reinforced_concrete} onClick={() => add("reinforced_concrete", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.construction?.reinforced_concrete === false} onClick={() => add("reinforced_concrete", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Is property a repaired PRC or similar?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.construction?.repaired_prc_or_similar} onClick={() => add("repaired_prc_or_similar", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.construction?.repaired_prc_or_similar === false} onClick={() => add("repaired_prc_or_similar", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div className='input' style={{ width: "100%" }}>
                <div className='label'>
                    If non-standard construction give details
                </div>
                <textarea type='text' rows={2} value={props.form?.property?.construction?.non_standard_construction} onChange={e => add("non_standard_construction", e.currentTarget.value)} />
            </div>
        </div>
    );
}

export default Construction;