import React from 'react';

function Roads(props) {
    function add(state, e) {
        props.addToForm({
            property: {
                ...props.form?.property,
                roads: {
                    ...props.form?.property?.roads,
                    [state]: e
                }
            }
        })
    }

    return (
        <div className='roads'>
            <div className='header'>
                4. &nbsp;&nbsp;Roads
            </div>

            <div className='boolean'>
                <div className='label' style={{ marginBottom: "10px" }}>
                    Are roads made up, paved, drained and adopted?
                </div>
                <label class="container">Yes
                    <input type="checkbox" checked={props.form?.property?.roads?.roads_adopted} onClick={() => add("roads_adopted", true)} />
                    <span class="checkmark"></span>
                </label>
                <label class="container">No
                    <input type="checkbox" checked={props.form?.property?.roads?.roads_adopted === false} onClick={() => add("roads_adopted", false)} />
                    <span class="checkmark"></span>
                </label>
            </div>

            <div style={{ marginTop: "-20px", display: "block" }} className='input'>
                <div className='label'>
                    If no, who is responsible for cost?
                </div>
                <input style={{ width: "200px" }} type="text" value={props.form?.property?.roads?.responsible_for_cost} onChange={e => add("responsible_for_cost", e.currentTarget.value)} />
            </div>

            <div className='input'>
                <div className='label'>
                    What is the approximate cost to make up to adoption standards?
                </div>
                &nbsp;&nbsp;£<input style={{ marginLeft: "-15px", backgroundColor: "transparent", paddingLeft: "20px", width: "60px", textAlign: "left" }} type="number" value={props.form?.property?.tenure?.leasehold?.service_charge} onChange={e => add("service_charge", parseInt(e.currentTarget.value))} />
            </div>
        </div>
    );
}

export default Roads;